
import Vue from "vue";
import LocaleSwitcher from "./LocaleSwitcher.vue";

export default Vue.extend({
  name: "app-footer", 
  components: {
    LocaleSwitcher: LocaleSwitcher,
  },
  data() {
    return {
      isMobile: window.innerWidth <= 768
    };
  },
  /*
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  */
});
